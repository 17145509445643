import React, { useMemo } from "react";
import { Link, NavLink, Outlet, useParams } from "react-router-dom";
import "./styles/jobdetailstyles.css";
import EastIcon from "@mui/icons-material/East";
import { useSelector } from "react-redux";

import DataNotFound from "../../components/DataNotFound";
import { Helmet } from "react-helmet";
import { CircularProgress } from "@mui/material";
function JobDetail() {
  const { id } = useParams();

  const { jobs } = useSelector((state) => state.position);

  const job = useMemo(() => {
    return jobs
      ? jobs.find((internship) => internship.id === id)
        ? jobs.find((internship) => internship.id === id)
        : null
      : {};
  }, [jobs, id]);

  return (
    <>
      <Helmet>
        <title>Jobs | Detail</title>
      </Helmet>
      {jobs ? (
        <>
          {job === null && (
            <>
              <DataNotFound color="white" />
            </>
          )}
          {job !== null && (
            <div
              style={{
                marginTop: "130px",
                backgroundColor: "#f8f9fa",
                paddingTop: "60px",
                paddingBottom: "60px",
                minWidth: "280px",
              }}
              className="bg-light"
            >
              <section className="container h-100">
                <div className="column">
                  <section className="d-flex flex-column">
                    <h4
                      className="font-weight-bold mb-4 position-title"
                      // style={{ fontSize: "32px" }}
                    >
                      {job.title}
                    </h4>
                    <h2
                      style={{
                        fontWeight: "600",
                        // fontSize: "25px",
                        textTransform: "capitalize",
                      }}
                      className="mb-4 position-type"
                    >
                      {job.positionType} - {job.positionCategory}
                    </h2>
                    <section
                      style={{
                        fontWeight: "500",

                        textAlign: "justify",
                      }}
                      className="overview"
                    >
                      {job.overview}
                    </section>
                  </section>
                  <div className="gap-7 flex-column d-flex justify-content-center align-items-center my-20">
                    <div
                      style={{ marginTop: "40px" }}
                      className="d-flex flex-column w-100"
                    >
                      <section className="w-100">
                        <div className="container">
                          <div
                            className="row"
                            // style={{ border: "1px solid red", gap: "0px" }}
                          >
                            <div
                              className="col-sm-4 tab  px-1 mb-2"
                              // style={{ border: "1px solid green" }}
                            >
                              <NavLink
                                to="."
                                end
                                replace={true}
                                style={({ isActive, isPending }) => {
                                  return {
                                    height: "50px",

                                    fontWeight: "600",
                                    border: isActive ? "" : "1px solid #000",
                                    color: isActive ? "white" : "#000",
                                    backgroundColor: isActive
                                      ? "#000"
                                      : "white",
                                    textDecoration: "none",
                                    boxShadow: isActive
                                      ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                      : "",
                                  };
                                }}
                                className="d-flex tab-item justify-content-center align-items-center rounded  pointer"
                              >
                                Responsibility
                              </NavLink>
                            </div>
                            <div
                              className="col-sm-4 tab  px-1 mb-2"
                              // style={{ border: "1px solid green" }}
                            >
                              <NavLink
                                to="requirement"
                                replace={true}
                                style={({ isActive, isPending }) => {
                                  return {
                                    height: "50px",

                                    fontWeight: "600",

                                    border: isActive ? "" : "1px solid #000",
                                    color: isActive ? "white" : "#000",
                                    backgroundColor: isActive
                                      ? "#000"
                                      : "white",
                                    textDecoration: "none",
                                    boxShadow: isActive
                                      ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                      : "",
                                  };
                                }}
                                className="d-flex tab-item justify-content-center align-items-center rounded  pointer"
                              >
                                Requirement
                              </NavLink>
                            </div>
                            {job.benefits && job.benefits.trim() && (
                              <div
                                className="col-sm-4 tab  px-1 mb-2"
                                // style={{ border: "1px solid green" }}
                              >
                                <NavLink
                                  to="benefits"
                                  replace={true}
                                  style={({ isActive, isPending }) => {
                                    return {
                                      height: "50px",
                                      fontWeight: "600",

                                      border: isActive
                                        ? ""
                                        : "1px solid #168C9E",
                                      color: isActive ? "white" : "#000",
                                      backgroundColor: isActive
                                        ? "#000"
                                        : "white",
                                      textDecoration: "none",
                                      boxShadow: isActive
                                        ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                        : "",
                                    };
                                  }}
                                  className="d-flex tab-item justify-content-center align-items-center rounded  pointer "
                                >
                                  Benefits
                                </NavLink>
                              </div>
                            )}
                          </div>
                        </div>

                        <div style={{ minHeight: "200px" }}>
                          <Outlet context={job} />
                        </div>
                        <section
                          className="d-flex justify-content-center move"
                          style={{ marginTop: "50px" }}
                        >
                          <Link
                            to={`/form/${id}`}
                            style={{
                              backgroundColor: "#000",
                              color: "white",
                              fontSize: "25px",
                              width: "190px",
                              height: "56px",
                              textDecoration: "none",
                            }}
                          >
                            <div className="rounded text-center py-2 pointer">
                              Apply Here
                              <EastIcon className="right-icon" />
                            </div>
                          </Link>
                        </section>
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      ) : (
        <div
          style={{ height: "300px", marginTop: "170px", marginBottom: "170px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-column align-items-center">
            <CircularProgress />
            <section className="mt-3" style={{ fontSize: "20px" }}>
              Loading details. Please wait!
            </section>
          </div>
        </div>
      )}
    </>
  );
}

export default JobDetail;
