import React from "react";
import "../styles/mainstyles.css";
import { Link } from "react-router-dom";
function SingleJob({
  id,
  title,
  overview,
  type,
  requirements,
  responsibility,
  benefits,
  avatar,
}) {
  return (
    <div
      className="col-md-6   mb-4"
      style={{ paddingRight: "12px", paddingLeft: "12px" }}
    >
      <div className="shade py-4  h-100 ">
        <section
          className="d-flex flex-column align-items-center w-100 justify-content-between"
          style={{ gap: "8px" }}
        >
          <div
            style={{
              width: "100px",
              height: "100px",
              // border: "1px solid red",
            }}
          >
            <img
              src={`${process.env.REACT_APP_URL}/candidates/documents?query=${avatar}`}
              alt={`${title}`}
              className="black-white"
            />
          </div>
          <h1
            className="w-100 text-center px-4"
            style={{
              color: "black",
              fontSize: "22px",
              lineHeight: "1",
              wordWrap: "break-word",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {title}
          </h1>
        </section>
        <section>
          <section
            className="text-center px-3 "
            style={{
              fontSize: "15px",

              fontFamily: "Raleway, sans-serif",
              lineHeight: "1.4",

              color: "#333333",
            }}
          >
            {overview}
          </section>
          <Link
            to={`jobs/${id}`}
            className="pointer text-center"
            style={{
              fontSize: "15px",
              fontFamily: "Raleway, sans-serif",
              textDecoration: "underline",
              //color: "#000",
              color: "#087b8d",
            }}
          >
            <section>Read More</section>
          </Link>
        </section>
      </div>
    </div>
  );
}

export default SingleJob;
