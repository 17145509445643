import "./styles/intern.css";
import SingleIntern from "./components/SingleIntern";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

function Internships() {
  const { internships } = useSelector((state) => state.position);

  return (
    <section>
      <Helmet>
        <title>Internships</title>
      </Helmet>
      <div
        style={{
          marginTop: "130px",
          backgroundColor: "#f8f9fa",

          paddingTop: "60px",
          paddingBottom: "60px",
          minWidth: "284px",

          minHeight: "500px",
        }}
      >
        {internships && internships.length === 0 && (
          <section
            style={{
              fontSize: "20px",
              fontFamily: "Raleway, sans-serif",

              padding: "120px 0px",
            }}
            className="w-100 m-auto  text-center"
          >
            <div className="container">
              <section className="mb-3">
                Thank you for visiting our page.
              </section>
              Unfortunately, we don’t have any open positions available at this
              time. Be sure to follow up on our website and social media pages
              for possible future opportunities.
            </div>
          </section>
        )}
        {internships && internships.length !== 0 && (
          <section
            className="container h-100 "
            style={{ backgroundColor: "", maxWidth: "1300px" }}
          >
            <div className="row h-100 d-flex ">
              <div className="col-lg-4 h-100 d-flex flex-column job-center mb-4">
                <h2
                  className="font-weight-bold"
                  style={{
                    fontSize: "32px",
                    fontFamily: "Raleway, sans-serif",
                  }}
                >
                  INTERNSHIPS
                </h2>
                <section className="job-line"></section>
              </div>
              <div className="col-lg-8 ">
                <section className="row">
                  {internships
                    ?.filter((internship) => internship.status === "active")
                    .map((internship) => {
                      return (
                        <SingleIntern
                          key={internship.id}
                          id={internship.id}
                          title={internship.title}
                          overview={internship.overview}
                          type={internship.positionType}
                          requirement={internship.requirements}
                          responsibility={internship.responsibility}
                          benefits={internship.benefits}
                          avatar={internship.avatar}
                        />
                      );
                    })}
                </section>
              </div>
            </div>
          </section>
        )}
        {!internships && (
          <div
            style={{ height: "300px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="d-flex flex-column align-items-center">
              <CircularProgress />
              <section className="mt-3" style={{ fontSize: "20px" }}>
                Loading internships. Please wait.
              </section>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Internships;
