import React from "react";
import { TextField } from "@mui/material";
import { useNavigate, useOutletContext } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { Helmet } from "react-helmet";

function QuestionsForm() {
  const navigate = useNavigate();
  const {
    data,
    files,
    id,
    handleApply,
    applyResponse,
    answers,
    loading,
    handleAnswers,
    setAnswersError,
    answersError,
    questions,
    errors,
  } = useOutletContext();

  const handleContinue = () => {
    var tempErrors = {};
    var container = [];
    const questionsNumber = questions.length;

    for (var i = 0; i < questionsNumber; i++) {
      if (!answers[questions[i].id]) {
        const answer = answers[questions[i].id]
          ? answers[questions[i].id].answer
          : "";

        if (!answer.trim()) {
          tempErrors = { ...tempErrors, [i]: true };
          container.push(i);
        } else {
          tempErrors = { ...tempErrors, [i]: false };
          if (container.includes(i)) {
            container = container.filter((id) => id !== i);
          }
        }
      } else if (answers[questions[i].id]) {
        const answer = answers[questions[i].id]
          ? answers[questions[i].id].answer
          : "";
        if (!answer.trim()) {
          tempErrors = { ...tempErrors, [i]: true };
          container.push(i);
        } else {
          tempErrors = { ...tempErrors, [i]: false };
          if (container.includes(i)) {
            container = container.filter((id) => id !== i);
          }
        }
      } else {
        tempErrors = { ...tempErrors, [i]: false };
        if (container.includes(i)) {
          container = container.filter((id) => id !== i);
        }
      }
    }
    // return;
    setAnswersError(tempErrors);

    if (container.length === 0) {
      const formData = new FormData();
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("applicationDescription", data.applicationDescription);
      formData.append("age", data.age);
      formData.append("address", data.address);
      formData.append("email", data.email);
      formData.append("yearsOfExperience", data.yearsOfExperience);
      formData.append("educationLevel", data.educationLevel);
      formData.append("position", id);
      formData.append("cv", files.cv);
      formData.append("otherRelevantDocuments", files.other);
      formData.append("sex", data.sex);
      formData.append("phoneNumber", `${data.phoneNumber}`);

      var tempAnswers = [];
      for (let i = 0; i < questions.length; i++) {
        tempAnswers.push(answers[questions[i].id]);
      }
      formData.append("answers", JSON.stringify(tempAnswers));

      setAnswersError({});
      handleApply(formData);

      return;
    }
  };
  return (
    <>
      <Helmet>
        <title>Form | Questions</title>
      </Helmet>
      <div
        className="container"
        style={{
          marginTop: "15px",
          minHeight: "300px",
          //   backgroundColor: "black",
        }}
      >
        <div className="row justify-content-center">
          <div
            className="col-12 mb-3"
            style={{ fontSize: "17px", minHeight: "230px" }}
          >
            {questions.map((question, index) => {
              return (
                <>
                  <section className="d-flex" key={index}>
                    <section
                      style={{ fontFamily: "sans-serif" }}
                      className="mr-1"
                    >
                      {index + 1}.
                    </section>
                    <section style={{ fontFamily: "sans-serif" }}>
                      {question.question}
                    </section>
                  </section>
                  <section className="px-4 py-2">
                    <TextField
                      label={answersError[index] ? "Required" : "Answer"}
                      error={answersError[index]}
                      rows={1}
                      placeholder="Enter Answer"
                      variant="outlined"
                      fullWidth
                      required
                      inputProps={{ maxLength: 1500 }}
                      onChange={(e) => {
                        handleAnswers(e, question.id);
                      }}
                      value={
                        answers[question.id] ? answers[question.id].answer : ""
                      }
                      sx={{
                        "& textarea": {
                          backgroundColor: "#f8f9fa",

                          borderRadius: "4px",
                          padding: "8px",
                        },
                      }}
                    />
                  </section>
                </>
              );
            })}
          </div>

          <div className="col-12">
            {!loading && (
              <section
                style={{
                  gap: "100px",
                  height: "40px",
                  marginLeft: "40px",
                  marginRight: "40px",
                }}
                className="d-flex justify-content-center"
              >
                <button
                  style={{
                    width: "150px",
                    backgroundColor: "#000",
                    color: "white",
                    fontSize: "18px",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={() => navigate(-1)}
                  className="rounded pointer "
                >
                  Back
                </button>

                <button
                  style={{
                    width: "150px",
                    backgroundColor: "#000",
                    color: "white",
                    fontSize: "18px",
                    border: "none",
                    outline: "none",
                  }}
                  onClick={handleContinue}
                  className="rounded pointer "
                >
                  Apply
                </button>
              </section>
            )}
            {loading && (
              <section
                style={{
                  gap: "100px",
                  height: "40px",
                  marginLeft: "40px",
                  marginRight: "40px",
                }}
                className="d-flex justify-content-center"
              >
                <CircularProgress />
              </section>
            )}
            <div
              className="col-12 d-flex my-2 justify-content-center"
              style={{ backgroundColor: "", height: "20px" }}
            >
              <section className="d-flex flex-column">
                {!applyResponse.success && errors.length !== 0 && (
                  <>
                    {errors.map((err, index) => {
                      if (err === "Operation failed, try again.") {
                        return (
                          <>
                            <section
                              key={index}
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "16px",
                                color: "rgb(179, 12, 12)",
                              }}
                            >
                              {err}
                            </section>
                          </>
                        );
                      } else if (
                        err === "Phone number and/or Email already used"
                      ) {
                        return (
                          <>
                            <section
                              key={index}
                              style={{
                                fontFamily: "sans-serif",
                                fontSize: "16px",

                                color: "rgb(179, 12, 12)",
                              }}
                            >
                              {err}
                            </section>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionsForm;
