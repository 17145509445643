import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MainListItems from "./components/ListItems";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminDetail,
  getAllAdminPositions,
  getAllQuestions,
} from "../../redux/position/positionRedux";
import { getAllCandidates } from "../../redux/candidate/candidateRedux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { getAdmins, removeToken } from "../../redux/admin/adminRedux";
import LogoutIcon from "@mui/icons-material/Logout";
import LockIcon from "@mui/icons-material/Lock";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

function HRmain() {
  const dispatch = useDispatch();
  const { isLogged } = useSelector((state) => state.admin);

  useEffect(() => {
    dispatch(getAllAdminPositions());
    dispatch(getAllCandidates());
    dispatch(getAdminDetail());
    dispatch(getAllQuestions());
    dispatch(getAdmins());
  }, [dispatch]);

  if (!isLogged) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <>
      <Dashboard />
    </>
  );
}

export default HRmain;

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

function Dashboard() {
  const [open, setOpen] = React.useState(true);
  const { adminDetail } = useSelector((state) => state.position);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={defaultTheme}>
          <Box sx={{ display: "flex" }}>
            {/* <CssBaseline /> */}
            <AppBar
              style={{ backgroundColor: "#000", minWidth: "400px" }}
              position="absolute"
              open={open}
            >
              <Toolbar
                sx={{
                  pr: "24px",
                  height: "50px",
                }}
              >
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  sx={{
                    marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                  size="large"
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ flexGrow: 1 }}
                  style={{ fontWeight: "bold", fontSize: "25px" }}
                >
                  iCog-ACC
                </Typography>
                {/* <Typography> */}
                <section
                  className="d-flex align-items-center justify-content-center pointer"
                  onMouseEnter={() => setDropdownOpen(true)}
                  onMouseLeave={() => setDropdownOpen(false)}
                  style={{
                    position: "relative",
                    // backgroundColor: "red",
                    height: "100%",
                  }}
                >
                  <AccountCircleIcon style={{ fontSize: "35px" }} />

                  <section
                    style={{
                      fontSize: "18px",
                      fontWeight: "bold",
                      fontFamily: "sans-serif",
                    }}
                    className="admin-email mr-1"
                  >
                    {localStorage.getItem("email")}
                  </section>
                  <KeyboardArrowDownIcon style={{ fontSize: "30px" }} />
                </section>
                {/* </Typography> */}
                {isDropdownOpen && (
                  <div
                    style={{
                      position: "absolute",
                      right: "37px",
                      marginTop: `${
                        !adminDetail?.superAdmin ? "210px" : "260px"
                      }`,
                      // backgroundColor: "red",
                      width: "300px",
                      // height: "100px",
                    }}
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                    className=""
                  >
                    <ul className="list-unstyled rounded admin-dropdown">
                      {adminDetail?.superAdmin && (
                        <ListItemButton onClick={() => navigate("addaccount")}>
                          <ListItemIcon>
                            <PersonAddIcon />
                          </ListItemIcon>
                          <ListItemText
                            style={{ color: `black` }}
                            // style={{ color: `${selected === "Candidates" ? "white" : ""}` }}
                            primary="Accounts"
                          />
                        </ListItemButton>
                      )}
                      <ListItemButton
                        onClick={() => navigate("changepassword")}
                      >
                        <ListItemIcon>
                          <LockIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary="Change Password"
                          style={{ color: `black` }}
                        />
                      </ListItemButton>

                      <ListItemButton
                        onClick={() => {
                          dispatch(removeToken());
                          localStorage.removeItem("email");
                          navigate("/login");
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText
                          style={{ color: `black` }}
                          // style={{ color: `${selected === "Candidates" ? "white" : ""}` }}
                          primary="Logout"
                        />
                      </ListItemButton>
                    </ul>
                  </div>
                )}
              </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
                }}
              >
                <IconButton onClick={toggleDrawer} size="large">
                  <ChevronLeftIcon />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav">
                <MainListItems />
              </List>
            </Drawer>
            <Box
              component="main"
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                minWidth: "400px",
                overflow: "auto",
              }}
              // style={{ minWidth: "400px" }}
            >
              <Outlet />
            </Box>
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}
