import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { Backdrop, Checkbox, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm } from "antd";
import { updateAdminList } from "../../../redux/admin/adminRedux";
import { Helmet } from "react-helmet";
export const convertDate = (inputDateString) => {
  const date = new Date(inputDateString);

  const year = date.getFullYear();
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();
  return `${day} ${month} ${year}`;
};
function AdminDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({ open: false, data: {} });
  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState({
    positionId: "",
    delete: false,
    update: false,
  });
  const [notFound, setNotFound] = useState(false);
  const { allAdminPositions, adminDetail } = useSelector(
    (state) => state.position
  );
  const allPositions = allAdminPositions;

  const token = localStorage.getItem("token");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_URL}/permission/auth/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setData(res.data))
      .catch((err) => setNotFound(true));
  }, [id, token]);

  const giveAdminPermission = async (e) => {
    e.preventDefault();
    const { positionId } = access;
    const position = allPositions.find(
      (position) => position.id === positionId
    );
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_URL}/permission`,
        {
          adminId: id,
          positionId,
          permission: `${access.delete ? "delete" : ""},${
            access.update ? "update" : ""
          }`,
          positionName: position.title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { auth, ...others } = res.data;
      setData((prev) => {
        return {
          ...prev,
          permissions: [...data.permissions, res.data],
        };
      });
      setOpen(false);
      dispatch(
        updateAdminList({ ...data, permissions: [...data.permissions, others] })
      );
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
  };
  const filterPositions = () => {
    const filteredPositions = allAdminPositions?.filter((position) => {
      const permission = data?.permissions?.find(
        (p) => p.positionId === position.id
      );

      if (!permission) return position;
      return null;
    });

    return filteredPositions;
  };

  const removePermission = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`${process.env.REACT_APP_URL}/permission/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const newPermissions = data.permissions.filter(
        (permission) => permission.id !== id
      );
      setData((prev) => {
        return { ...prev, permissions: newPermissions };
      });
      dispatch(updateAdminList({ ...data, permissions: newPermissions }));
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
    setLoading(false);
  };

  const updatePermission = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_URL}/permission/${edit.data.id}`,
        { permissions: edit.data.permission },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const newPermissions = data.permissions.map((permission) => {
        if (permission.id === edit.data.id) {
          const { auth, ...others } = res.data;
          return others;
        }

        return permission;
      });
      setData((prev) => {
        return { ...prev, permissions: newPermissions };
      });
      setEdit({ open: false, data: {} });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
      }
    }
  };

  return (
    <div style={{ margin: "100px 10px" }}>
      <Helmet>
        <title>Admins | Detail</title>
      </Helmet>
      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <form
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
          className="py-4 rounded"
          onSubmit={giveAdminPermission}
        >
          <div className="d-flex justify-content-end align-items-center mb-2">
            <section
              style={{
                width: "30px",
                height: "30px",
                background: "#c2e7ff",
                borderRadius: "50%",
              }}
              className="d-flex justify-content-center align-items-center"
              onClick={() => setOpen(false)}
            >
              <ClearIcon style={{ cursor: "pointer" }} />
            </section>
          </div>
          <div>
            <div style={{}}>
              Admin: <span className="font-weight-bold">{data?.email}</span>
            </div>
            <div className="mt-2">
              Position :{" "}
              <select
                required
                name="positionId"
                id="positionId"
                onChange={(e) =>
                  setAccess((prev) => {
                    return {
                      ...prev,
                      positionId: e.target.value,
                    };
                  })
                }
              >
                <option value="">---select position---</option>
                {filterPositions()?.map((position) => (
                  <option key={position.id} value={position.id}>
                    {position.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex align-items-center mt-2">
              <section>Can Delete?</section>
              <Checkbox
                onChange={(e) =>
                  setAccess((prev) => {
                    return { ...prev, delete: e.target.checked };
                  })
                }
                checked={access.delete}
              />
            </div>
            <div className="d-flex align-items-center ">
              <section>Can Update?</section>
              <Checkbox
                onChange={(e) =>
                  setAccess((prev) => {
                    return { ...prev, update: e.target.checked };
                  })
                }
                checked={access.update}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                style={{
                  background: "#000",
                  width: "130px",
                  color: "white",
                  letterSpacing: "2px",
                  cursor: "pointer",
                  border: "none",
                }}
                className="text-center py-2 rounded"
              >
                Proceed
              </button>
            </div>
          </div>
        </form>
      </Backdrop>
      <Backdrop
        sx={{ color: "black", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={edit.open}
      >
        <form
          style={{
            background: "white",
            fontSize: "20px",
            paddingLeft: "40px",
            paddingRight: "40px",
          }}
          className="py-4 rounded"
          onSubmit={updatePermission}
        >
          <div className="d-flex justify-content-end align-items-center mb-2">
            <section
              style={{
                width: "30px",
                height: "30px",
                background: "#c2e7ff",
                borderRadius: "50%",
              }}
              className="d-flex justify-content-center align-items-center"
              onClick={() =>
                setEdit((prev) => {
                  return { data: {}, open: false };
                })
              }
            >
              <ClearIcon style={{ cursor: "pointer" }} />
            </section>
          </div>
          <div>
            <div style={{}}>
              Admin: <span className="font-weight-bold">{data?.email}</span>
            </div>
            <div className="mt-2">Position: {edit.data.positionName}</div>
            <div className="d-flex align-items-center mt-2">
              <section>Can Delete?</section>
              <Checkbox
                onChange={(e) => {
                  const [remove, update] = edit.data?.permission?.split(",");

                  setEdit((prev) => {
                    return {
                      ...prev,
                      data: {
                        ...edit.data,
                        permission: `${e.target.checked ? "delete" : ""},${
                          update ? "update" : ""
                        }`,
                      },
                    };
                  });
                }}
                checked={edit.data?.permission?.split(",")[0] ? true : false}
              />
            </div>
            <div className="d-flex align-items-center ">
              <section>Can Update?</section>
              <Checkbox
                onChange={(e) => {
                  const [remove, update] = edit.data?.permission?.split(",");

                  setEdit((prev) => {
                    return {
                      ...prev,
                      data: {
                        ...edit.data,
                        permission: `${remove ? "delete" : ""},${
                          e.target.checked ? "update" : ""
                        }`,
                      },
                    };
                  });
                }}
                checked={edit.data?.permission?.split(",")[1] ? true : false}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                style={{
                  background: "#000",
                  width: "130px",
                  color: "white",
                  letterSpacing: "2px",
                  cursor: "pointer",
                  border: "none",
                }}
                className="text-center py-2 rounded"
                // onClick={() => update}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </Backdrop>
      <section className="d-flex justify-content-between align-items-end">
        <section className="font-weight-bold mb-2" style={{ fontSize: "20px" }}>
          {data?.email}
        </section>
        {!data?.superAdmin && (
          <Fab
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "#000",
              color: "white",
            }}
            className="mb-2 mx-3"
            aria-label="add"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        )}
      </section>
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="text-center">
              Position
            </th>
            <th scope="col" className="text-center">
              Position Posted By
            </th>
            <th scope="col" className="text-center">
              Email
            </th>
            <th scope="col" className="text-center">
              Permission Given By
            </th>
            <th scope="col" className="text-center">
              Delete
            </th>
            <th scope="col" className="text-center">
              Update
            </th>
            <th scope="col" className="text-center">
              Given Date
            </th>
            <th scope="col" className="text-center">
              Options
            </th>
          </tr>
        </thead>
        <tbody>
          {!loading ? (
            data?.permissions.map((singlePermission, index) => {
              const {
                positionName,
                permission,
                createdOn,
                id,
                positionId,
                givenBy,
              } = singlePermission;
              const [remove, update] = permission?.split(",");
              const position = allAdminPositions.find(
                (position) => position.id === positionId
              );

              return (
                <tr key={id}>
                  <th
                    scope="row"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {index + 1}
                  </th>
                  <td
                    className="text-center font-weight-bold"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {positionName}
                  </td>
                  <td
                    className="text-center font-weight-bold"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {position ? position.createdBy : "Not Found"}
                  </td>
                  <td
                    className="text-center font-weight-bold"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {data.email}
                  </td>
                  <td
                    className="text-center font-weight-bold"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {givenBy}
                  </td>
                  <td
                    className="text-center"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {remove ? (
                      <CheckIcon style={{ color: "#000" }} />
                    ) : (
                      <ClearIcon style={{ color: "#B30C0C" }} />
                    )}
                  </td>
                  <td
                    className="text-center"
                    // onClick={() => navigate(`/admin/list/${id}`)}
                  >
                    {update ? (
                      <CheckIcon style={{ color: "#000" }} />
                    ) : (
                      <ClearIcon style={{ color: "#B30C0C" }} />
                    )}
                  </td>
                  <td className="text-center font-weight-bold">
                    {convertDate(createdOn)}
                  </td>
                  <td>
                    <div
                      className="d-flex justify-content-center"
                      style={{ gap: "20px" }}
                    >
                      <Popconfirm
                        placement="bottomRight"
                        title={"Are you sure want to delete this permission?"}
                        onConfirm={() => removePermission(id)}
                        okText="Yes"
                        cancelText="No"
                      >
                        <section
                          style={{
                            background: "#B30C0C",
                            cursor: "pointer",
                            color: "white",
                            letterSpacing: "2px",
                            fontSize: "17px",
                          }}
                          className="px-4 py-1 rounded"
                          // onClick={}
                        >
                          Remove
                        </section>
                      </Popconfirm>

                      <section
                        style={{
                          background: "#000",
                          color: "white",
                          letterSpacing: "2px",
                          fontSize: "17px",
                          cursor: "pointer",
                        }}
                        className="px-4 py-1 rounded mr-4"
                        onClick={() =>
                          setEdit({ open: true, data: singlePermission })
                        }
                      >
                        Edit
                      </section>
                    </div>
                  </td>
                </tr>
              );
            })
          ) : (
            <div
              style={{ height: "300px" }}
              className="d-flex justify-content-center align-items-center"
            >
              <div className="d-flex flex-column align-items-center">
                <CircularProgress />
                <section className="mt-3" style={{ fontSize: "20px" }}>
                  Deleting permission. Please wait!
                </section>
              </div>
            </div>
          )}
        </tbody>
      </table>
      {!data && (
        <div
          style={{ height: "300px" }}
          className="d-flex justify-content-center align-items-center"
        >
          <div className="d-flex flex-column align-items-center">
            <CircularProgress />
            <section className="mt-3" style={{ fontSize: "20px" }}>
              Loading permissions...
            </section>
          </div>
        </div>
      )}
      {data?.permissions.length === 0 && !data?.superAdmin && (
        <div
          role="row"
          className="d-flex align-items-center flex-column"
          style={{ marginTop: "100px" }}
        >
          <section>No permissions found.</section>
          <section>
            Click the plus icon above to add permissions for{" "}
            <span className="font-weight-bold" style={{ fontSize: "19px" }}>
              {data?.email}
            </span>
          </section>
        </div>
      )}
      {data?.superAdmin && (
        <div
          role="row"
          className="d-flex align-items-center flex-column"
          style={{ marginTop: "100px", fontSize: "20px" }}
        >
          <section className="mb-3">
            Admin <span className="font-weight-bold">{data?.email}</span> has
            super admin access.
          </section>
          <section className="mb-3">
            Which allows <span className="font-weight-bold">{data?.email}</span>{" "}
            to have full access on every position available.
          </section>
          <section className="font-weight-bold">
            No need to add permission for this admin.
          </section>
        </div>
      )}
    </div>
  );
}

export default AdminDetail;
