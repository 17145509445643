import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import React, { useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import axios from "axios";
import { Alert, notification } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { removeToken } from "../../../redux/admin/adminRedux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
function ChangePassword() {
  const bearerToken = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  const [data, setData] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });
  const [api, contextHolder] = notification.useNotification();

  const [error, setError] = useState({
    confirmPassword: false,
    password: false,
    oldPassword: false,
    confirmPasswordError: "",
  });
  const handleChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const isValid = (password, confirmPassword, oldPassword) => {
    var passwordError = false;
    var confirmPasswordError = false;
    var oldPasswordError = false;
    var confirmError = "";

    if (oldPassword) {
      oldPasswordError = false;
    } else if (!oldPassword) {
      oldPasswordError = true;
    }
    if (password) {
      passwordError = false;
    } else if (!password) {
      passwordError = true;
    }

    if (!confirmPassword) {
      confirmPasswordError = true;
      confirmError = "";
      return {
        password: passwordError,
        confirmPassword: confirmPasswordError,
        confirmPasswordError: confirmError,
        oldPassword: oldPasswordError,
      };
    } else if (confirmPassword) {
      confirmPasswordError = false;
      confirmError = "";
    }

    if (confirmPassword !== password) {
      confirmPasswordError = true;
      confirmError = "Password does not match";
    } else if (confirmPassword === password) {
      confirmPasswordError = false;
      confirmError = "";
    }

    return {
      password: passwordError,
      confirmPassword: confirmPasswordError,
      confirmPasswordError: confirmError,
      oldPassword: oldPasswordError,
    };
  };

  const handleChangePasswordRequest = async (password, oldPassword) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/change-password`,
        {
          newPassword: password,
          oldPassword: oldPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 201) {
        setChangePasswordError({ error: false, msg: "" });
        setData({ password: "", confirmPassword: "", oldPassword: "" });
        api.open({
          message: "iCog-ACC",
          description: `Password Successfully Changed`,
          placement: "bottomRight",
          icon: (
            <CheckCircleIcon
              style={{
                color: "#000",
              }}
            />
          ),
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        if (err.response.data.message === "Unauthorized") {
          setChangePasswordError({ error: true, msg: "Invalid Old Password" });
        } else {
          dispatch(removeToken());
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          navigate("/login");
        }
      } else if (err.response && err.response.status === 400) {
        setChangePasswordError({ error: true, msg: "Weak new password" });
      }
    }
  };
  const handleNewPassword = (e) => {
    e.preventDefault();
    setChangePasswordError({ error: false, msg: "" });
    const { password, confirmPassword, oldPassword } = data;
    const result = isValid(password, confirmPassword, oldPassword);

    if (result.password || result.confirmPassword || result.oldPassword) {
      setError(result);
      return;
    }

    setError(result);
    handleChangePasswordRequest(password, oldPassword);
  };
  return (
    <>
      <Helmet>
        <title>Password</title>
      </Helmet>
      <div style={{ marginTop: "100px", zIndex: "50000" }}>{contextHolder}</div>
      <div
        className="container"
        style={{ marginTop: "200px", minWidth: "411px" }}
      >
        <div className="row row d-flex justify-content-center">
          <div className="col-md-6">
            <div
              className="card text-center"
              style={{
                // boxShadow: "0px 2px 4px 1px rgba(184,184,184,1)",
                // webkitBoxShadow: "0px 4px 8px -2px rgba(171, 169, 169, 1)",

                // mozBoxShadow: "0px 2px 4px 1px rgba(184,184,184,1)",
                // backgroundColor: "white",
                boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
              }}
            >
              <div
                className="card-header p-4"
                // style={{ backgroundColor: "white" }}
              >
                <h5 className="mb-0" style={{ fontFamily: "sans-serif" }}>
                  Change Password
                </h5>
              </div>
              <div
                className="card-body p-4"
                // style={{ backgroundColor: "white" }}
              >
                <p className="mb-4" style={{ fontFamily: "sans-serif" }}>
                  Set a new password
                </p>
                <form className="mb-4">
                  <div className="form-outline mb-4">
                    <FormControl
                      variant="standard"
                      className=" w-75"
                      //   style={{ backgroundColor: "red" }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        style={{
                          color: `${error.oldPassword ? "red" : ""}`,
                        }}
                      >
                        Old Password *
                      </InputLabel>
                      <Input
                        id="input-with-icon-adornment"
                        name="oldPassword"
                        error={error.oldPassword}
                        onChange={handleChange}
                        value={data.oldPassword}
                        type={showOldPassword ? "text" : "password"}
                        className="w-100"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              //   onMouseDown={handleMouseDownPassword}
                              onClick={handleClickShowOldPassword}
                              size="large"
                            >
                              {showOldPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-outline mb-4">
                    <FormControl
                      variant="standard"
                      className=" w-75"
                      //   style={{ backgroundColor: "red" }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        style={{
                          color: `${error.password ? "red" : ""}`,
                        }}
                      >
                        New Password *
                      </InputLabel>
                      <Input
                        id="input-with-icon-adornment"
                        name="password"
                        error={error.password}
                        onChange={handleChange}
                        value={data.password}
                        type={showPassword ? "text" : "password"}
                        className="w-100"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              //   onMouseDown={handleMouseDownPassword}
                              onClick={handleClickShowPassword}
                              size="large"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </div>
                  <div className="form-outline ">
                    <FormControl
                      variant="standard"
                      className=" w-75"
                      //   style={{ backgroundColor: "red" }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        style={{
                          fontFamily: "sans-serif",
                          color: `${
                            error.confirmPasswordError
                              ? ""
                              : error.confirmPassword
                              ? "red"
                              : ""
                          }`,
                        }}
                      >
                        Confirm New Password *
                      </InputLabel>
                      <Input
                        id="input-with-icon-adornment"
                        name="confirmPassword"
                        error={
                          error.confirmPasswordError
                            ? false
                            : error.confirmPassword
                            ? true
                            : false
                        }
                        type={showConfirmPassword ? "text" : "password"}
                        onChange={handleChange}
                        value={data.confirmPassword}
                        style={{ width: "100%" }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              //   onMouseDown={handleMouseDownPassword}
                              onClick={handleClickShowConfirmPassword}
                              size="large"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <section
                      style={{
                        height: "15px",
                        fontSize: "15px",
                        fontFamily: "sans-serif",
                        color: "red",
                      }}
                      className="d-flex justify-content-center"
                    >
                      {error.confirmPassword && error.confirmPasswordError}
                    </section>
                  </div>
                  <section
                    style={{
                      height: "15px",
                      marginBottom: "70px",
                      marginTop: "10px",
                    }}
                  >
                    {changePasswordError.error && (
                      <Alert
                        message={`${changePasswordError.msg}`}
                        type="error"
                        style={{
                          color: "rgb(179, 12, 12)",
                          fontSize: "18px",
                          fontFamily: "sans-serif",

                          width: "100%",
                        }}
                      />
                    )}
                  </section>
                  <button
                    className="pointer login-btn px-4 rounded w-100"
                    onClick={handleNewPassword}
                  >
                    Change
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
