import React, { useState } from "react";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
} from "@mui/material";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";
import { notification } from "antd";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { addAdmin } from "../../../redux/admin/adminRedux";
import { Helmet } from "react-helmet";
function AddAccount() {
  const dispatch = useDispatch();
  const [data, setData] = useState({
    email: "",
    admin: true,
    superAdmin: false,
    canPost: false,
  });
  const token = localStorage.getItem("token");
  const { adminDetail } = useSelector((state) => state.position);
  const [error, setError] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  if (!adminDetail?.superAdmin) {
    return <Navigate to="/admin" replace={true} />;
  }

  const handleAdminAccessRequest = async (data) => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/signup`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch(addAdmin({ ...response.data, permissions: [] }));
      if (response.status === 201) {
        api.open({
          message: "iCog-ACC",
          description: `Admin access is successfully given. Generated password is sent to ${data.email}.`,
          placement: "BottomRight",

          icon: (
            <CheckCircleIcon
              style={{
                color: "#000",
              }}
            />
          ),
        });
      }
    } catch (err) {
      if (!err.response) {
        api.open({
          message: "iCog-ACC",
          description: `Failed to give admin access. Try again.`,
          placement: "BottomRight",
          style: {
            fontSize: "100px",
          },
          icon: (
            <ErrorIcon
              style={{
                color: "rgb(179, 12, 12)",
              }}
            />
          ),
        });
      } else {
        if (err.response && err.response.status === 403) {
          api.open({
            message: "iCog-ACC",
            description: `Failed to give admin access. User already given admin access.`,
            placement: "BottomRight",

            icon: (
              <ErrorIcon
                style={{
                  color: "rgb(179, 12, 12)",
                }}
              />
            ),
          });
        } else if (err.response && err.response.status === 400) {
          api.open({
            message: "iCog-ACC",
            description: `Failed to give admin access. Invalid input is detected.`,
            placement: "BottomRight",

            icon: (
              <ErrorIcon
                style={{
                  color: "rgb(179, 12, 12)",
                }}
              />
            ),
          });
        } else {
          api.open({
            message: "iCog-ACC",
            description: `Failed to give admin access. Try again.`,
            placement: "BottomRight",

            icon: (
              <ErrorIcon
                style={{
                  color: "rgb(179, 12, 12)",
                }}
              />
            ),
          });
        }
      }
    }
    setLoading(false);
  };
  const validateInput = (email) => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    var isValid = emailPattern.test(email);

    if (!isValid) {
      return false;
    }

    return true;
  };

  const handleGiveAdminAccess = (e) => {
    e.preventDefault();
    setLoading(false);
    const isValid = validateInput(data.email);

    if (!isValid) {
      setError(true);
      return;
    }

    setError(false);
    handleAdminAccessRequest(data);
  };

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <span style={{ fontSize: "30px", fontWeight: "bold " }}>iCog-ACC</span>
      <div
        className="container"
        style={{ marginTop: "200px", minWidth: "411px" }}
      >
        {contextHolder}
        <div className="row row d-flex justify-content-center">
          <div className="col-md-6">
            <div
              className="card text-center"
              style={{
                boxShadow: "rgba(1, 12, 14, 0.2) 0px 2px 20px",
              }}
            >
              <div className="card-header p-4">
                <h5 className="mb-0" style={{ fontFamily: "sans-serif" }}>
                  Give Admin Access
                </h5>
              </div>
              <div className="card-body p-4">
                <form className="mb-4">
                  <div
                    className="form-outline"
                    style={{
                      marginTop: "40px",
                      marginBottom: "70px",
                    }}
                  >
                    <FormControl
                      variant="standard"
                      className=" w-75"
                      //   style={{ backgroundColor: "red" }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        style={
                          {
                            //   color: `${error.oldPassword ? "red" : ""}`,
                          }
                        }
                      >
                        Email *
                      </InputLabel>
                      <Input
                        id="input-with-icon-adornment"
                        name="oldPassword"
                        error={error}
                        // onChange={handleChange}
                        // value={data.oldPassword}
                        // type={showOldPassword ? "text" : "password"}
                        onChange={(e) =>
                          setData((prev) => {
                            return { ...prev, email: e.target.value };
                          })
                        }
                        className="w-100"
                      />
                    </FormControl>
                    <FormControl variant="standard" className=" w-75 mt-3">
                      <div className="d-flex align-items-center ">
                        <section>Can Post?</section>
                        <Checkbox
                          onChange={(e) =>
                            setData((prev) => {
                              return { ...prev, canPost: e.target.checked };
                            })
                          }
                          checked={data.canPost}
                        />
                      </div>
                      <div className="d-flex align-items-center ">
                        <section>Super Admin</section>

                        <Checkbox
                          onChange={(e) =>
                            setData((prev) => {
                              return {
                                ...prev,
                                superAdmin: e.target.checked,
                                canPost: e.target.checked
                                  ? e.target.checked
                                  : data.canPost,
                              };
                            })
                          }
                          checked={data.superAdmin}
                        />
                      </div>
                    </FormControl>
                    <section
                      style={{
                        height: "15px",
                        fontSize: "14px",
                        fontFamily: "sans-serif",
                        color: "rgb(179, 12, 12)",
                        // backgroundColor: "red",
                      }}
                      className="mb-3 d-flex justify-content-center"
                    >
                      <section
                        className="w-75 d-flex justify-content-start"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        {error && "Invalid Email"}
                      </section>
                    </section>
                  </div>
                  {loading ? (
                    <section className="d-flex justify-content-center align-items-center">
                      <CircularProgress />
                    </section>
                  ) : (
                    <button
                      className="pointer login-btn px-4 rounded w-75"
                      onClick={handleGiveAdminAccess}
                    >
                      Continue
                    </button>
                  )}
                  {/* <button
                    className="pointer login-btn px-4 rounded w-75"
                    onClick={handleGiveAdminAccess}
                  >
                    Continue
                  </button> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddAccount;
