import React, { createContext, useContext, useEffect, useState } from "react";
import { MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";
import "../styles/hr.css";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { notification } from "antd";

import {
  CircularProgress,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert } from "antd";
import ForgotPassword from "./ForgotPassword";
import { useDispatch } from "react-redux";
import { setToken } from "../../../redux/admin/adminRedux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function Login() {
  const [api, contextHolder] = notification.useNotification();

  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, setData, setPage, success } = useContext(DataContext);
  const [loginResponse, setLoginResponse] = useState({ error: false, msg: "" });
  const [forGetResponse, setForGetResponse] = useState({
    error: false,
    msg: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleChange = (e) => {
    setData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const validateInput = (email, password) => {
    var emailError = false;
    var passwordError = false;
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    var isValid = emailPattern.test(email);

    if (!isValid) {
      emailError = true;
    } else if (isValid) {
      emailError = false;
    }

    if (!password) {
      passwordError = true;
    } else if (password) {
      passwordError = false;
    }

    return { email: emailError, password: passwordError };
  };

  const handleLoginRequest = async (email, password) => {
    setLoading(true);
    setLoginResponse({ error: false, msg: "" });
    setForGetResponse({ error: false, msg: "" });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/signin`,
        {
          email,
          password,
        }
      );

      if (response.status === 201) {
        localStorage.setItem("token", response.data.acces_token);
        localStorage.setItem("email", email);
        dispatch(setToken(response.data.acces_token));
        navigate("/admin", { replace: true });
      }
    } catch (err) {
      if (err.response && err.message !== "Network Error") {
        setLoginResponse({ error: true, msg: "Invalid Email and/or Password" });
      } else {
        setLoginResponse({ error: true, msg: "Internal Server Error" });
      }
    }
    setLoading(false);
  };

  const handleLogin = () => {
    setLoginResponse({ error: false, msg: "" });
    setForGetResponse({ error: false, msg: "" });
    const { email, password } = data;
    const result = validateInput(email, password);
    if (result.email || result.password) {
      setError(result);
      setLoading(false);

      return;
    }
    setError(result);
    handleLoginRequest(email, password);
  };

  const handleForgetRequest = async (email) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/auth/forget-password`,
        { email: email }
      );

      if (response.status === 201) {
        setData((prev) => {
          return { ...prev, password: "" };
        });
        setPage("forgot");
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setForGetResponse({ error: true, msg: "User Not Found" });
      } else if (err.response && err.response.status === 400) {
        setForGetResponse({ error: true, msg: "Invalid Email" });
      } else {
        setForGetResponse({ error: true, msg: "Operation Failed" });
      }
    }
    setLoading(false);
  };
  const handleForgot = () => {
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    var isValid = emailPattern.test(data.email);
    setLoginResponse({ error: false, msg: "" });
    setForGetResponse({ error: false, msg: "" });
    if (!data.email.trim() || !isValid) {
      setError({ email: true, password: false });
      return;
    }
    setError({ email: false, password: false });
    handleForgetRequest(data.email);
  };

  useEffect(() => {
    if (success.success) {
      api.open({
        message: "iCog-ACC",
        description: `${success.msg}`,
        icon: (
          <CheckCircleIcon
            style={{
              color: "#000",
            }}
          />
        ),
      });
    }
  }, [success.success, success.msg, api]);

  return (
    <div style={{ minHeight: "100vh" }} className="d-flex align-items-center">
      {contextHolder}

      <MDBContainer className="p-3" style={{ minWidth: "300px" }}>
        <MDBRow>
          <MDBCol
            col="10"
            md="6"
            className="mb-4 d-flex justify-content-center align-items-center"
          >
            <img
              src="/whitelogo.png"
              className="img-fluid"
              alt="icog"
              style={{ width: "90%" }}
            />
          </MDBCol>

          <MDBCol
            className="d-flex flex-column justify-content-center"
            col="4"
            md="6"
          >
            <TextField
              id="filled-basic"
              label="Email *"
              variant="standard"
              value={data.email}
              name="email"
              type="email"
              error={error.email}
              style={{ background: "white" }}
              onChange={handleChange}
            />
            <section
              style={{
                height: "15px",
                fontSize: "14px",
                fontFamily: "sans-serif",
                color: "rgb(179, 12, 12)",
              }}
              className="mb-3"
            >
              {error.email && "Invalid Email"}
            </section>
            <FormControl variant="standard" className="mb-4">
              <InputLabel
                htmlFor="input-with-icon-adornment"
                style={{ color: `${error.password ? "rgb(179, 12, 12)" : ""}` }}
              >
                Password *
              </InputLabel>
              <Input
                id="input-with-icon-adornment"
                type={showPassword ? "text" : "password"}
                name="password"
                error={error.password}
                onChange={handleChange}
                value={data.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="large"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <section
                style={{
                  height: "15px",
                  fontSize: "14px",
                  fontFamily: "sans-serif",
                  color: "rgb(179, 12, 12)",
                }}
                className="mb-3"
              >
                {error.password && "Invalid Password"}
              </section>
            </FormControl>
            {loading ? (
              <section className="d-flex justify-content-center align-items-center">
                <CircularProgress />
              </section>
            ) : (
              <button
                onClick={handleLogin}
                className="login-btn rounded pointer"
              >
                Login
              </button>
            )}

            <span
              id="forgot-text"
              style={{
                fontFamily: "sans-serif",
                textDecoration: "none",
              }}
              className="small pointer  ms-5 my-2"
              onClick={handleForgot}
            >
              Forgot password?
            </span>

            <section
              style={{
                height: "47px",
              }}
              className="mb-1 mt-1 text-center"
            >
              {loginResponse.error && (
                <Alert
                  message={loginResponse.msg}
                  type="error"
                  style={{
                    color: "rgb(179, 12, 12)",
                    fontSize: "17px",
                    fontFamily: "sans-serif",
                  }}
                />
              )}
              {forGetResponse.error && (
                <Alert
                  message={forGetResponse.msg}
                  type="error"
                  style={{
                    color: "rgb(179, 12, 12)",
                    fontSize: "17px",
                    fontFamily: "sans-serif",
                  }}
                />
              )}
            </section>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}
export const DataContext = createContext();
const LoginMain = () => {
  const [page, setPage] = useState("login");
  const [data, setData] = useState({ email: "", password: "" });
  const [success, setSuccess] = useState({ success: false, msg: "" });

  return (
    <DataContext.Provider
      value={{ setPage, data, setData, success, setSuccess }}
    >
      {page === "login" && <Login />}
      {page === "forgot" && <ForgotPassword />}
    </DataContext.Provider>
  );
};

export default LoginMain;
