import React from "react";
import { Outlet } from "react-router-dom";
import { useMemo, useState } from "react";
import { Grid, Card, CardContent, Typography, AlertTitle } from "@mui/material";
import "./styles/form.css";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { serverErrorFound } from "../../redux/position/positionRedux";
import { notification } from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from "react-helmet";

function ApplyMain() {
  const [api, contextHolder] = notification.useNotification();

  const dispatch = useDispatch();
  const [applyResponse, setApplyResponse] = useState({
    success: false,
  });
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const { allPositions, allQuestions } = useSelector((state) => state.position);
  const position = useMemo(() => {
    return allPositions
      ? allPositions.find((position) => position.id === id)
        ? allPositions.find((position) => position.id === id)
        : null
      : {};
  }, [allPositions, id]);

  const questions = useMemo(() => {
    return allQuestions
      ? allQuestions.filter((question) => question.position.id === id)
      : [];
  }, [allPositions, allQuestions, id]);

  const [answers, setAnswers] = useState({});
  const [answersError, setAnswersError] = useState({});
  const [files, setFiles] = useState({ cv: "", other: "" });
  const [fileError, setFileError] = useState({ cv: "", other: "" });
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    age: "",
    sex: "",
    yearsOfExperience: "",
    educationLevel: "",
    applicationDescription: "",
  });
  const navigate = useNavigate();

  const handleAnswers = (e, id) => {
    setAnswers((prev) => {
      return { ...prev, [id]: { answer: e.target.value, questionId: id } };
    });
  };

  const handleApply = async (data) => {
    setApplyResponse({ success: false });
    setErrors([]);
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_URL}/application/apply`,
        data
      );

      if (response.status === 201) {
        api.open({
          message: "iCog-ACC",
          description: `Job Application Submitted. 
          Thank you for applying for the position of ${position.title} at iCog-ACC.`,
          icon: (
            <CheckCircleIcon
              style={{
                color: "#000",
              }}
            />
          ),
        });
        setTimeout(function () {
          navigate("/");
        }, 4000);
      }

      setLoading(false);
    } catch (err) {
      if (err.message === "Network Error" || err.response.status === 500) {
        dispatch(serverErrorFound());
      } else if (err.response && err.response.status === 404) {
        if (
          err.response &&
          err.response.data.message === "Questions and answers does not much"
        ) {
          setErrors(["Operation failed, try again."]);
          setApplyResponse({
            success: false,
          });
        } else if (
          err.response &&
          err.response.data.message === "Already applied to this position"
        ) {
          setErrors(["Phone number and/or Email already used"]);
          setApplyResponse({
            success: false,
          });
        }
      } else if (err.response && err.response.status === 400) {
        setErrors(err.response.data.message);

        setApplyResponse({
          success: false,
        });
      }
    }
    setLoading(false);
  };

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phoneNumber: false,
    address: false,
    age: false,
    sex: false,
    yearsOfExperience: false,
    educationLevel: false,
    applicationDescription: false,
  });

  const isValidEmailAndPhone = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/candidates/check-applied-before`,
        {
          email: data.email,
          phoneNumber: data.phoneNumber,
          position: position.id,
        }
      );

      return { success: true, errors: [] };
    } catch (err) {
      if (err.response.status === 500) {
        return {
          success: false,
          errors: ["Server error, try again "],
        };
      } else {
        if (err.response.status === 404) {
          return {
            success: false,
            errors: ["Phone number and/or Email already used"],
          };
        }
        if (err.response.status === 400) {
          return { success: false, errors: err.response.data.message };
        }
      }
    }
  };
  const validateInput = () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      age,
      sex,
      yearsOfExperience,
      educationLevel,
      applicationDescription,
    } = data;
    setApplyResponse({ success: false });
    setErrors([]);
    if (firstName.trim()) {
      setError((prev) => {
        return { ...prev, firstName: false };
      });
    }
    if (!firstName.trim()) {
      setError((prev) => {
        return { ...prev, firstName: true };
      });
    }
    if (lastName.trim()) {
      setError((prev) => {
        return { ...prev, lastName: false };
      });
    }
    if (!lastName.trim()) {
      setError((prev) => {
        return { ...prev, lastName: true };
      });
    }
    if (email.trim()) {
      var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      var isValid = emailPattern.test(email);

      setError((prev) => {
        return { ...prev, email: isValid ? false : "Invalid Email" };
      });
    }
    if (!email.trim()) {
      setError((prev) => {
        return { ...prev, email: true };
      });
    }
    if (phoneNumber.trim()) {
      setError((prev) => {
        return { ...prev, phoneNumber: false };
      });
    }
    if (!phoneNumber.trim()) {
      setError((prev) => {
        return { ...prev, phoneNumber: true };
      });
    }
    if (address.trim()) {
      setError((prev) => {
        return { ...prev, address: false };
      });
    }
    if (!address.trim()) {
      setError((prev) => {
        return { ...prev, address: true };
      });
    }
    if (age.trim()) {
      setError((prev) => {
        return { ...prev, age: false };
      });
    }
    if (!age.trim()) {
      setError((prev) => {
        return { ...prev, age: true };
      });
    }
    if (sex.trim()) {
      setError((prev) => {
        return { ...prev, sex: false };
      });
    }
    if (!sex.trim()) {
      setError((prev) => {
        return { ...prev, sex: true };
      });
    }
    if (yearsOfExperience.trim()) {
      setError((prev) => {
        return { ...prev, yearsOfExperience: false };
      });
    }
    if (!yearsOfExperience.trim()) {
      setError((prev) => {
        return { ...prev, yearsOfExperience: true };
      });
    }
    if (educationLevel.trim()) {
      setError((prev) => {
        return { ...prev, educationLevel: false };
      });
    }
    if (!educationLevel.trim()) {
      setError((prev) => {
        return { ...prev, educationLevel: true };
      });
    }
    if (applicationDescription.trim()) {
      setError((prev) => {
        return { ...prev, applicationDescription: false };
      });
    }
    if (!applicationDescription.trim()) {
      setError((prev) => {
        return { ...prev, applicationDescription: true };
      });
    }
    if (!files.cv) {
      setFileError((prev) => {
        return { ...prev, cv: "Required" };
      });
      return;
    } else if (files.cv.type !== "application/pdf") {
      setFileError((prev) => {
        return {
          ...prev,
          cv: "Only pdf files are allowed",
        };
      });
      return;
    } else if (files.other) {
      if (files.other.type !== "application/pdf") {
        setFileError((prev) => {
          return {
            ...prev,
            other: "Only pdf files are allowed",
          };
        });
        return;
      }
    }
  };
  const handleNext = async () => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      address,
      age,
      sex,
      yearsOfExperience,
      educationLevel,
      applicationDescription,
    } = data;

    validateInput();
    var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    var isValid = emailPattern.test(email);

    if (
      !firstName.trim() ||
      !lastName.trim() ||
      !email.trim() ||
      !isValid ||
      !phoneNumber.trim() ||
      !address.trim() ||
      !age.trim() ||
      !sex.trim() ||
      !yearsOfExperience.trim() ||
      !educationLevel.trim() ||
      !applicationDescription.trim() ||
      !files.cv
    ) {
      return;
    }
    const result = await isValidEmailAndPhone();

    if (!result.success) {
      setErrors(result.errors);
      return;
    }

    navigate("questions");
  };

  const handleChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };
  return (
    <>
      {contextHolder}
      <Helmet>
        <title>Form</title>
      </Helmet>
      {position === null && (
        <>
          <div
            style={{
              marginTop: "130px",
              backgroundColor: "red",
              paddingTop: "60px",
              paddingBottom: "60px",
              minWidth: "280px",
              height: "400px",
              gap: "20px",
            }}
            className="bg-light d-flex flex-column align-items-center justify-content-center"
          >
            <section style={{ fontSize: "20px" }}>
              The data you are looking for is not found!
            </section>
            <button
              className="rounded pointer back-btn"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </>
      )}

      {applyResponse.success && (
        <div
          style={{
            marginTop: "130px",
            backgroundColor: "red",
            paddingTop: "60px",
            paddingBottom: "60px",
            minWidth: "280px",
            height: "400px",
            gap: "20px",
          }}
          className="bg-light d-flex flex-column align-items-center justify-content-center"
        >
          <Alert
            severity="success"
            style={{
              // color: "black",
              // backgroundColor: "white",
              // backgroundColor: "rgba(0, 0, 0, 0.8)",
              fontSize: "16px",
            }}
            className="d-flex justify-content-center p-2"
          >
            <section>
              <AlertTitle>Success</AlertTitle>
              You have successfully applied for
              <strong> {position && position.title}</strong>
            </section>
            <section className="d-flex justify-content-center mt-4">
              <button
                className="rounded pointer back-btn"
                onClick={() => navigate("/")}
              >
                Back
              </button>
            </section>
          </Alert>
        </div>
      )}

      {position !== null && !applyResponse.success && (
        <div
          style={{
            marginTop: "130px",
            backgroundColor: "#f8f9fa",
            minWidth: "284px",

            width: "100%",
          }}
          className="py-4"
        >
          <div className="container">
            <div className="row">
              <div className="col">
                <Grid
                  style={{
                    margin: "auto",
                  }}
                >
                  <Card style={{ borderRadius: "15px" }}>
                    <CardContent>
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12">
                            <Typography gutterBottom variant="h5">
                              {position && position.title}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <Outlet
                        context={{
                          position: position,
                          data: data,
                          setData: setData,
                          handleChange: handleChange,

                          files: files,
                          setFiles: setFiles,
                          setFileError: setFileError,
                          setApplyResponse: setApplyResponse,
                          id: position.id,
                          handleApply: handleApply,
                          applyResponse: applyResponse,
                          fileError: fileError,
                          handleNext: handleNext,
                          validateInput: validateInput,
                          error,
                          answers,
                          setAnswers,
                          handleAnswers,
                          answersError,
                          setAnswersError,
                          questions,
                          errors,
                          loading,
                        }}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const Application = () => {
  return <ApplyMain />;
};
export default Application;
