import { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import WorkIcon from "@mui/icons-material/Work";

import SchoolIcon from "@mui/icons-material/School";
import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Divider } from "@mui/material";
import { useSelector } from "react-redux";
function MainListItems() {
  const [selected, setSelected] = useState("Jobs");
  const navigate = useNavigate();
  const { adminDetail } = useSelector((state) => state.position);

  return (
    <>
      <>
        <ListItemButton
          style={{ backgroundColor: `${selected === "Jobs" ? "#000" : ""}` }}
          onClick={() => {
            setSelected("Jobs");
            navigate("/admin");
          }}
        >
          <ListItemIcon>
            <WorkIcon
              style={{ color: `${selected === "Jobs" ? "white" : ""}` }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: `${selected === "Jobs" ? "white" : ""}` }}
            primary="Jobs"
          />
        </ListItemButton>
        <ListItemButton
          style={{
            backgroundColor: `${selected === "Internships" ? "#000" : ""}`,
          }}
          onClick={() => {
            setSelected("Internships");
            navigate("/admin/internships");
          }}
        >
          <ListItemIcon>
            <SchoolIcon
              style={{ color: `${selected === "Internships" ? "white" : ""}` }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: `${selected === "Internships" ? "white" : ""}` }}
            primary="Internships"
          />
        </ListItemButton>
        <ListItemButton
          style={{
            backgroundColor: `${selected === "Candidates" ? "#000" : ""}`,
          }}
          onClick={() => {
            setSelected("Candidates");
            navigate("/admin/candidatelist");
          }}
        >
          <ListItemIcon>
            <PersonIcon
              style={{ color: `${selected === "Candidates" ? "white" : ""}` }}
            />
          </ListItemIcon>
          <ListItemText
            style={{ color: `${selected === "Candidates" ? "white" : ""}` }}
            primary="Candidates"
          />
        </ListItemButton>
      </>
      {adminDetail?.superAdmin && (
        <>
          <Divider className="my-2" />
          <ListItemButton
            style={{
              backgroundColor: `${selected === "Admins" ? "#000" : ""}`,
            }}
            onClick={() => {
              setSelected("Admins");
              navigate("/admin/list");
            }}
          >
            <ListItemIcon>
              <AdminPanelSettingsIcon
                style={{ color: `${selected === "Admins" ? "white" : ""}` }}
              />
            </ListItemIcon>
            <ListItemText
              style={{ color: `${selected === "Admins" ? "white" : ""}` }}
              primary="Admins"
            />
          </ListItemButton>
        </>
      )}
    </>
  );
}

export default MainListItems;
