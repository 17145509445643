// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shade {
  background-color: white;
  transition: ease-in-out 0.2s;
}
.shade:hover {
  box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);
  -webkit-box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);
  -moz-box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);
}

.job-content {
  text-align: justify;
}

.title-line {
  width: 50px;
  height: 3px;
  background-color: #000;
}

@media (max-width: 991px) {
  .position-center {
    align-items: center;
  }

  .title-line {
    width: 200px;
  }
}

@media (max-width: 290px) {
  .position-center {
    margin-left: 35px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Internships/styles/intern.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,4BAA4B;AAC9B;AACA;EACE,oDAAoD;EACpD,4DAA4D;EAC5D,yDAAyD;AAC3D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,sBAAsB;AACxB;;AAEA;EACE;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;EACd;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".shade {\n  background-color: white;\n  transition: ease-in-out 0.2s;\n}\n.shade:hover {\n  box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);\n  -webkit-box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);\n  -moz-box-shadow: 0px 0px 13px -2px rgba(222, 219, 219, 1);\n}\n\n.job-content {\n  text-align: justify;\n}\n\n.title-line {\n  width: 50px;\n  height: 3px;\n  background-color: #000;\n}\n\n@media (max-width: 991px) {\n  .position-center {\n    align-items: center;\n  }\n\n  .title-line {\n    width: 200px;\n  }\n}\n\n@media (max-width: 290px) {\n  .position-center {\n    margin-left: 35px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
