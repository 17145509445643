import React, { useEffect, useMemo, useState } from "react";
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../styles/hr.css";
import { Checkbox, Select } from "antd";
import { Button, Popconfirm, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { removeToken } from "../../../redux/admin/adminRedux";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  getAllAdminPositions,
  getAllQuestions,
} from "../../../redux/position/positionRedux";

import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import {
  CircularProgress,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import DataNotFound from "../../../components/DataNotFound";

import DeleteError from "./DeleteError";
import { getAllCandidates } from "../../../redux/candidate/candidateRedux";
import { Helmet } from "react-helmet";

export const returnPermissions = (adminDetail, positionId) => {
  if (adminDetail.superAdmin) {
    return { canPost: true, remove: true, update: true };
  } else if (adminDetail.permissions.length === 0) {
    return { canPost: adminDetail.canPost, remove: false, update: false };
  } else if (adminDetail.permissions.length > 0) {
    for (let i = 0; i < adminDetail.permissions.length; i++) {
      if (adminDetail.permissions[i].positionId === positionId) {
        const [remove, update] =
          adminDetail.permissions[i].permission.split(",");
        return { remove: remove ? true : false, update: update ? true : false };
      }
    }
    return { remove: false, update: false };
  }
};
export const createdByDetails = (adminsList, email) => {
  const admin = adminsList?.find((admin) => admin.email === email);
  return admin ? admin : {};
};
function HRjobDetail() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bearerToken = localStorage.getItem("token");

  const { adminJobs, allQuestions, adminDetail, allAdminPositions } =
    useSelector((state) => state.position);
  const { adminsList } = useSelector((state) => state.admin);
  const [showAdmin, setShowAdmin] = useState(false);
  const jobs = adminJobs;
  const [deleteResponse, setDeleteResponse] = useState({
    error: false,
    success: false,
  });

  const position = useMemo(() => {
    return jobs
      ? jobs.find((job) => job.id === id)
        ? jobs.find((job) => job.id === id)
        : null
      : {};
  }, [jobs, id]);

  const questions = useMemo(() => {
    return allQuestions
      ? allQuestions.filter((question) => question.position.id === id)
      : [];
  }, [jobs, allQuestions, id]);

  const handleRemove = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_URL}/positions/${position.id}`,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        dispatch(getAllAdminPositions());
        dispatch(getAllCandidates());
        setDeleteResponse({ error: false, success: true });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      } else {
        setDeleteResponse({
          error: true,
          success: false,
        });
      }
    }
  };
  const createQuestion = async () => {
    const temp = Questions.questions.filter((question) => question.trim());
    const questions = temp.map((question) => {
      return { question: question, choices: [], positionId: id };
    });

    try {
      await axios.post(
        `${process.env.REACT_APP_URL}/questions/add-questions`,
        {
          questions: questions,
        },
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      setQuestions({ questions: [], question: "" });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");

        navigate("/login");
      }
    }
  };

  const updateQuestion = async () => {
    for (let i = 0; i < data.questions.length; i++) {
      const question = data.questions[i];
      if (question.question.trim()) {
        try {
          await axios.patch(
            `${process.env.REACT_APP_URL}/questions/${question.id}`,
            { question: question.question, choices: [], positionId: id },
            {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
              },
            }
          );
        } catch (err) {
          if (err.response && err.response.status === 401) {
            dispatch(removeToken());
            localStorage.removeItem("token");
            localStorage.removeItem("email");

            navigate("/login");
          }
        }
      }
    }
  };
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState({
    title: "",
    positionType: "",
    category: "",
    skipDemo: "",
    overview: "",
    benefits: "",
    requirements: "",
    req: "",
    responsibility: "",
    res: "",
    benefit: "",
    questions: [],
  });
  const [addQuestion, setAddQuestion] = useState(false);

  const [Questions, setQuestions] = useState({
    questions: [],
    question: "",
  });
  console.log(data);
  const handleSave = async () => {
    const input = {
      title: data.title,
      positionType: data.positionType,
      positionCategory: data.category,
      skipDemo: data.skipDemo,
      overview: data.overview,
      requirements: data.requirements,
      responsibility: data.responsibility,
      benefits: data.benefits,
    };
    if (Questions.questions.length !== 0) {
      await createQuestion();
    }
    await updateQuestion();

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_URL}/positions/${id}`,
        input,
        {
          headers: {
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      if (response.status === 200) {
        dispatch(getAllCandidates());
        dispatch(getAllAdminPositions());
        setChecked(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(removeToken());
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate("/login");
      }
    }
    dispatch(getAllQuestions());
  };
  console.log(position?.benefits);
  useEffect(() => {
    position &&
      questions &&
      setData({
        title: position.title,
        positionType: position.positionType,
        category: position.positionCategory,
        skipDemo: position.skipDemo,
        overview: position.overview,
        requirements: position.requirements,
        req: "",
        responsibility: position.responsibility,
        res: "",
        benefits: position.benefits,
        benefit: "",
        questions: questions,
      });
  }, [position, questions]);

  return (
    <div style={{ minWidth: "790px" }}>
      <Helmet>
        <title>Jobs | Detail </title>
      </Helmet>
      {jobs ? (
        <>
          {position === null && (
            <>
              <DataNotFound path={localStorage.getItem("path")} />
            </>
          )}

          {deleteResponse.error && (
            <DeleteError setDeleteResponse={setDeleteResponse} />
          )}
          {position !== null && !deleteResponse.success && (
            <div
              style={{
                height: "100%",
              }}
            >
              <section className="container" style={{ marginTop: "120px" }}>
                <div className="column">
                  <section className="d-flex flex-column">
                    <section className="d-flex flex-column">
                      <section className="d-flex align-items-center justify-content-between">
                        {checked ? (
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            label="Title"
                            value={data.title}
                            inputProps={{ maxLength: 40 }}
                            onChange={(e) => {
                              setData((prev) => {
                                return { ...prev, title: e.target.value };
                              });
                            }}
                            className="font-weight-bold mb-4"
                            style={{ width: "300px" }}
                          />
                        ) : (
                          <h4
                            className="font-weight-bold mb-3 position-title"

                            // onClick={() => setChecked((prev) => !prev)}
                          >
                            {position.title}
                          </h4>
                        )}

                        {adminDetail &&
                          position &&
                          returnPermissions(adminDetail, position.id)
                            .update && (
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={checked}
                                  // value={checked}
                                  onChange={(e) => {
                                    !e.target.checked &&
                                      setData({
                                        title: position.title,
                                        positionType: position.positionType,
                                        category: position.positionCategory,
                                        skipDemo: position.skipDemo,
                                        overview: position.overview,
                                        requirements: position.requirements,
                                        responsibility: position.responsibility,
                                        req: "",
                                        res: "",
                                        benefit: "",
                                        benefits: position.benefits,
                                        questions: questions,
                                      });

                                    !e.target.checked &&
                                      setQuestions({
                                        questions: [],
                                        question: "",
                                      });

                                    setChecked(e.target.checked);
                                  }}
                                />
                              }
                              label="Edit"
                            />
                          )}
                      </section>
                      {checked ? (
                        <>
                          <Select
                            className="mb-3"
                            style={{ width: "300px" }}
                            defaultValue=""
                            value={data.positionType}
                            placement="bottomLeft"
                            onChange={(e) => {
                              setData((prev) => {
                                return { ...prev, positionType: e };
                              });
                            }}
                            options={[
                              {
                                value: "internship",
                                label: "Internship",
                              },
                              {
                                value: "part-time",
                                label: "Part-Time",
                              },
                              {
                                value: "full-time",
                                label: "Full-Time",
                              },
                              {
                                value: "contractual",
                                label: "Contractual",
                              },
                            ]}
                          />
                          <Select
                            className="mb-3"
                            style={{ width: "300px" }}
                            defaultValue=""
                            value={data.category}
                            placement="topLeft"
                            onChange={(e) => {
                              setData((prev) => {
                                return { ...prev, category: e };
                              });
                            }}
                            options={[
                              {
                                value: "onsite",
                                label: "Onsite",
                              },
                              {
                                value: "remote",
                                label: "Remote",
                              },
                              {
                                value: "hybrid",
                                label: "Hybrid",
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <>
                          <h2
                            style={{
                              fontWeight: "600",

                              textTransform: "capitalize",
                            }}
                            className="mb-3 position-type"
                          >
                            {position.positionType} -{" "}
                            {position.positionCategory}
                          </h2>
                          {adminDetail?.superAdmin && (
                            <section
                              style={{
                                fontWeight: "500",
                                fontSize: "22px",
                                textAlign: "justify",
                                color: "black",
                                position: "relative",
                              }}
                              className="mb-3"
                            >
                              created by:{" "}
                              <span
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onMouseEnter={() => setShowAdmin(true)}
                                onMouseLeave={() => setShowAdmin(false)}
                                onClick={() => {
                                  const id = createdByDetails(
                                    adminsList,
                                    position.createdBy
                                  ).id;

                                  id &&
                                    adminDetail?.id !== id &&
                                    navigate(`/admin/list/${id}`);
                                }}
                              >
                                {position.createdBy}
                              </span>
                              {showAdmin && (
                                <div
                                  style={{
                                    position: "absolute",
                                    padding: "20px",
                                    background: "white",
                                    left: 120,
                                    top: 28,
                                    fontSize: "18px",
                                  }}
                                  onMouseEnter={() => setShowAdmin(true)}
                                  onMouseLeave={() => setShowAdmin(false)}
                                >
                                  {!createdByDetails(
                                    adminsList,
                                    position.createdBy
                                  ).admin && <>Admin not found.</>}
                                  {createdByDetails(
                                    adminsList,
                                    position.createdBy
                                  ).admin && (
                                    <div>
                                      <section className="d-flex align-items-center">
                                        Super Admin:{" "}
                                        {createdByDetails(
                                          adminsList,
                                          position.createdBy
                                        ).superAdmin === true ? (
                                          <CheckIcon
                                            style={{ color: "#000" }}
                                            className="ml-3"
                                          />
                                        ) : (
                                          <ClearIcon
                                            style={{ color: "#B30C0C" }}
                                            className="ml-3"
                                          />
                                        )}
                                      </section>
                                      <section className="d-flex align-items-center">
                                        canPost:{" "}
                                        {createdByDetails(
                                          adminsList,
                                          position.createdBy
                                        ).canPost === true ? (
                                          <CheckIcon
                                            style={{ color: "#000" }}
                                            className="ml-3"
                                          />
                                        ) : (
                                          <ClearIcon
                                            style={{ color: "#B30C0C" }}
                                            className="ml-3"
                                          />
                                        )}
                                      </section>
                                      <section className="d-flex align-items-center">
                                        Permissions:
                                        {createdByDetails(
                                          adminsList,
                                          position.createdBy
                                        ).superAdmin === true ? (
                                          <span className="ml-2">
                                            Full Access
                                          </span>
                                        ) : (
                                          createdByDetails(
                                            adminsList,
                                            position.createdBy
                                          ).permissions.length
                                        )}
                                      </section>
                                      <section className="d-flex align-items-start">
                                        positions posted:{" "}
                                        <div className="ml-2">
                                          {allAdminPositions?.map((p) => {
                                            if (
                                              p.createdBy ===
                                              position?.createdBy
                                            ) {
                                              const route =
                                                p.positionType === "internship"
                                                  ? `/admin/internships/${p.id}`
                                                  : `/admin/jobs/${p.id}`;

                                              return (
                                                <section>
                                                  <Link
                                                    style={{
                                                      textDecoration: "none",
                                                      marginRight: "19px",
                                                      color: "#000",
                                                    }}
                                                    to={route}
                                                  >
                                                    {p.title}
                                                  </Link>
                                                </section>
                                              );
                                            }
                                            return <></>;
                                          })}
                                        </div>
                                      </section>
                                    </div>
                                  )}
                                </div>
                              )}
                            </section>
                          )}
                        </>
                      )}

                      <h5
                        style={{
                          fontWeight: "500",
                          fontSize: "20px",
                          textTransform: "capitalize",
                        }}
                        className="mb-3"
                      >
                        {checked ? (
                          <>
                            <Checkbox
                              onChange={(e) => {
                                const skipDemo = e.target.checked
                                  ? false
                                  : true;
                                setData((prev) => {
                                  return { ...prev, skipDemo: skipDemo };
                                });
                              }}
                              checked={!data.skipDemo}
                              style={{ fontSize: "20px", color: "black" }}
                            >
                              Demo
                            </Checkbox>
                          </>
                        ) : (
                          <div style={{ color: "black" }}>
                            {!position.skipDemo
                              ? "With Demonstration"
                              : "No Demonstration"}
                          </div>
                        )}
                      </h5>
                      {checked ? (
                        <>
                          <TextField
                            id="standard-basic"
                            variant="standard"
                            label="Overview"
                            value={data.overview}
                            onChange={(e) => {
                              setData((prev) => {
                                return { ...prev, overview: e.target.value };
                              });
                            }}
                            multiline
                            rows={4}
                            className="mb-2"
                            inputProps={{ maxLength: 450 }}

                            // style={{ width: "180px" }}
                          />
                          <section
                            style={{
                              color: `${
                                data.overview.length === 450 ? "red" : ""
                              }`,
                              fontFamily: "sans-serif",
                              textAlign: "end",
                            }}
                          >
                            {data.overview.length}/450
                          </section>
                        </>
                      ) : (
                        <section
                          style={{
                            fontWeight: "500",
                            fontSize: "18px",
                            textAlign: "justify",
                          }}
                        >
                          {position.overview}
                        </section>
                      )}
                    </section>
                  </section>
                  <div
                    className={`gap-7 flex-column d-flex justify-content-center align-items-center ${
                      checked ? "my-2" : "my-20"
                    } `}
                  >
                    <div
                      style={{ marginTop: "40px" }}
                      className="d-flex flex-column w-100"
                    >
                      <section className="w-100">
                        <ul
                          className="d-flex list-unstyled params"
                          style={{ height: "50px", gap: "20px" }}
                        >
                          <NavLink
                            to="."
                            replace={true}
                            end
                            style={({ isActive, isPending }) => {
                              return {
                                border: isActive ? "" : "1px solid #168C9E",
                                color: isActive ? "white" : "#000",
                                backgroundColor: isActive ? "#000" : "white",
                                textDecoration: "none",
                                boxShadow: isActive
                                  ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                  : "",

                                fontWeight: "600",
                              };
                            }}
                            className="d-flex justify-content-center align-items-center rounded  pointer params-item other-param"
                          >
                            Requirement
                          </NavLink>
                          <NavLink
                            to="responsibility"
                            replace={true}
                            style={({ isActive, isPending }) => {
                              return {
                                border: isActive ? "" : "1px solid #168C9E",
                                color: isActive ? "white" : "#000",
                                backgroundColor: isActive ? "#000" : "white",
                                textDecoration: "none",
                                boxShadow: isActive
                                  ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                  : "",

                                fontWeight: "600",
                              };
                            }}
                            className="d-flex justify-content-center align-items-center rounded  pointer params-item other-param"
                          >
                            Responsibility
                          </NavLink>

                          <NavLink
                            to="benefits"
                            replace={true}
                            style={({ isActive, isPending }) => {
                              return {
                                border: isActive ? "" : "1px solid #168C9E",
                                color: isActive ? "white" : "#000",
                                backgroundColor: isActive ? "#000" : "white",
                                textDecoration: "none",
                                boxShadow: isActive
                                  ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                  : "",

                                fontWeight: "600",
                              };
                            }}
                            className="d-flex justify-content-center align-items-center rounded  pointer params-item other-param"
                          >
                            Benefits
                          </NavLink>

                          <NavLink
                            to="questions"
                            replace={true}
                            style={({ isActive, isPending }) => {
                              return {
                                border: isActive ? "" : "1px solid #168C9E",
                                color: isActive ? "white" : "#000",
                                backgroundColor: isActive ? "#000" : "white",
                                textDecoration: "none",
                                boxShadow: isActive
                                  ? "0px 4px 8px -2px rgba(171, 169, 169, 1)"
                                  : "",

                                fontWeight: "600",
                              };
                            }}
                            className="d-flex justify-content-center align-items-center rounded  pointer params-item other-param"
                          >
                            Questions
                          </NavLink>
                        </ul>
                        <div style={{ minHeight: "300px" }}>
                          <Outlet
                            context={{
                              position: position,
                              questions: questions,
                              data: data,
                              setData: setData,
                              checked: checked,
                              Questions: Questions,
                              setQuestions,
                              addQuestion,
                              setAddQuestion,
                            }}
                          />
                        </div>
                      </section>
                      <section
                        className={`d-flex ${
                          checked
                            ? "justify-content-end"
                            : "justify-content-between"
                        } move`}
                        style={{ marginTop: "20px", marginBottom: "50px" }}
                      >
                        {!checked && (
                          <>
                            <Tooltip title="Back">
                              <div
                                style={{
                                  backgroundColor: "white",
                                  border: "1px solid #168C9E",
                                  color: "#000",
                                  fontSize: "18px",
                                  width: "150px",
                                  height: "40px",
                                }}
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="rounded text-center d-flex justify-content-center align-items-center pointer"
                              >
                                Back
                              </div>
                            </Tooltip>
                            {adminDetail &&
                              position &&
                              returnPermissions(adminDetail, position.id)
                                .remove && (
                                <Space>
                                  <Popconfirm
                                    title="Are you sure deactivate this job?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={handleRemove}
                                  >
                                    <Tooltip
                                      title={
                                        position.status === "active"
                                          ? "Deactivate"
                                          : "job is inactive"
                                      }
                                    >
                                      <Button
                                        style={{
                                          backgroundColor:
                                            position.status !== "active"
                                              ? "#f5f5f5"
                                              : "white",
                                          color:
                                            position.status !== "active"
                                              ? "#d3d3d3"
                                              : "rgb(179, 12, 12)",
                                          //backgroundColor: "white",
                                          //color: "rgb(179, 12, 12)",
                                          border: "1px solid red",
                                          fontSize: "18px",
                                          width: "150px",
                                          height: "40px",
                                        }}
                                        className="rounded text-center d-flex justify-content-center align-items-center pointer"
                                        disabled={position.status !== "active"}
                                      >
                                        {position.status === "active"
                                          ? "Deactivate"
                                          : "In-Active"}
                                      </Button>
                                    </Tooltip>
                                  </Popconfirm>
                                </Space>
                              )}
                          </>
                        )}
                        {checked && (
                          <>
                            <Space>
                              {!data.title.trim() ||
                              !data.positionType ||
                              !data.overview.trim() ||
                              !data.requirements.trim() ||
                              !data.responsibility.trim() ? (
                                <Button
                                  style={{
                                    backgroundColor: "",
                                    color: "",
                                    border: "",
                                    fontSize: "18px",
                                    width: "150px",
                                    height: "40px",
                                  }}
                                  disabled={true}
                                  className="rounded text-center d-flex justify-content-center align-items-center pointer"
                                >
                                  Save
                                </Button>
                              ) : (
                                <Popconfirm
                                  title="Are you sure save the changes?"
                                  okText="Yes"
                                  cancelText="No"
                                  onConfirm={handleSave}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "#000",
                                      color: "white",
                                      border: "1px solid #000",
                                      fontSize: "18px",
                                      width: "150px",
                                      height: "40px",
                                    }}
                                    className="rounded text-center d-flex justify-content-center align-items-center pointer"
                                  >
                                    Save
                                  </Button>
                                </Popconfirm>
                              )}
                            </Space>
                          </>
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          )}
        </>
      ) : (
        <div
          className="d-flex flex-column align-items-center"
          style={{ marginTop: "320px" }}
        >
          <CircularProgress />
          <section className="mt-3" style={{ fontSize: "20px" }}>
            Loading details. Please wait!
          </section>
        </div>
      )}
    </div>
  );
}

export default HRjobDetail;
